import React from 'react'
import HomeIcon from '@heroicons/react/solid/HomeIcon';
import TagIcon from '@heroicons/react/solid/TagIcon';
import CurrencyDollarIcon from '@heroicons/react/solid/CurrencyDollarIcon';
import UserCircleIcon from '@heroicons/react/solid/UserCircleIcon';
import GiftIcon from '@heroicons/react/solid/GiftIcon';
import { Link, useLocation } from 'react-router-dom';
import { menuItems } from './menuItems';

const SideBar = () => {
    const { pathname } = useLocation();
    return (
        <div className="sm:transform sm:-translate-x-20 md:translate-x-0 rounded-t-3xl w-full h-16 sm:h-full sm:w-28 fixed bottom-0 sm:flex sm:flex-col bg-primary">
            <div className='hidden sm:block w-14 h-14 bg-secondary my-3 mx-auto'>
                <p>logo</p>
            </div>
            <div className='flex sm:flex-col items-center justify-around sm:justify-center flex-1'>
                {menuItems.map(item => (
                    <Link key={item.link} to={item.link} className={`sm:my-1 w-14 h-14 rounded-2xl flex justify-center items-center z-10 transition duration-500 ease-in-out ${pathname === item.link ? 'bg-white transform -translate-y-8 sm:translate-x-14 z-10 shadow-md focus:outline-none hover:shadow-lg text-red-600' : 'text-secondary hover:bg-gray-200 hover:text-red-600 hover:shadow-md'}`}>
                        {item.name === 'Home'
                            ? <HomeIcon className="h-8 w-8" />
                            : item.name === 'Product'
                                ? <TagIcon className="h-8 w-8" />
                                : item.name === 'Amount'
                                    ? <CurrencyDollarIcon className="h-8 w-8" />
                                    : item.name === 'Profile'
                                        ? <UserCircleIcon className="h-8 w-8" />
                                        : item.name === 'Gifts'
                                            ? <GiftIcon className="h-8 w-8" />
                                            : null}
                    </Link>
                ))}
            </div>
            <div className='hidden sm:block w-14 h-14' />
        </div>
    )
}

export default SideBar
