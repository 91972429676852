/*eslint no-empty-function: "error"*/
/*eslint-env es6*/

import { createContext, useReducer } from 'react'
import produce from "immer"

export type Response = {
    data?: Response[] | undefined
    error?: any
    loading?: boolean
};

type IComponentState = {
    api: {
        [index: string]: Response;
    }
}

type IPayload = {
    key: string;
    data: any;
}

interface IAction {
    type: string
    payload: IPayload
}

interface IProps {
    children: React.ReactNode
}

const leagueReducer = (
    draft: IComponentState,
    action: IAction
) => {
    if (!draft.api[action.payload.key]) draft.api[action.payload.key] = {}
    switch (action.type) {
        case 'api_response': {
            draft.api[action.payload.key].data = action.payload.data;
            return;
        }

        case 'api_error': {
            draft.api[action.payload.key].error = action.payload.data;
            return;
        }

        case 'api_loading': {
            draft.api[action.payload.key].loading = action.payload.data;
            return;
        }


        default:
            return draft
    }
}

const curriedLeagueReducer = produce(leagueReducer);

const initialState: IComponentState = {
    api: {
        mutation: {
            data: undefined,
            error: undefined,
            loading: false
        }
    }
}

const intialDispatch: React.Dispatch<IAction> = () => {
    // do nothing
}

export const StateContext = createContext(initialState)
export const DispatchContext = createContext(intialDispatch)

const AppContext = ({ children }: IProps) => {
    const [state, dispatch] = useReducer(curriedLeagueReducer, initialState)

    return (
        <DispatchContext.Provider value={dispatch} >
            <StateContext.Provider value={state}> {children} </StateContext.Provider>
        </DispatchContext.Provider>
    )
}

export default AppContext
