import { Switch, Route, Redirect } from 'react-router-dom'
import React from 'react';
import Home from '../pages/Home';
import Product from '../pages/Product';
import PageNotFound from '../pages/PageNotFound';

const Routes = () => {
    return (
        <Switch>
            <Route path='/home' exact component={Home} />
            <Route path='/product' component={Product} />
            <Redirect from='/' exact to='/home' />
            <Route path='**' component={PageNotFound} />
        </Switch>
    )
}

export default Routes;
