import { useState } from 'react';
import { addPost, getTodosById } from '../../network/api';
import { useRequest, useMutation } from '../../network/httpClient';

const Home = () => {
    const [id, setId] = useState<number>(1);
    const todo = useRequest(['todos', { id }], getTodosById);
    const posts = useMutation(addPost);

    return (
        <div className='mt-8'>
            <h2 className='text-3xl font-semibold'>Dashboard</h2>
            {console.log("TODOS", todo)}
            {console.log("POST", posts)}
            <button onClick={() => setId(2)}>Set To 2</button>
            <button onClick={() => { posts.mutate({ title: "2", body: "2=TWO" }); }}>Increament</button>{posts.loading && <p>Loading...</p>}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-8">
                <div className="relative bg-gradient-to-b from-primary via-violet-900 to-gray-800 h-48 overflow-hidden rounded-xl shadow-card">
                    <div className='p-5 text-white'>
                        <h3 className='text-xl font-semibold'>Total Sales</h3>
                        <h1 className='text-5xl font-bold'>200</h1>
                    </div>
                    <div className='absolute left-0 bottom-0 w-full h-auto'>
                        <svg viewBox="0 0 250 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-2 61.1481V85H267V11.9219C267 11.9219 267 -7.87001 249.808 5.83208C232.617 19.5342 236.156 29.1765 221.492 33.7438C206.829 38.3112 199.356 22.4196 171.434 24.1016C143.512 25.7836 127.949 54.5508 105.195 58.1032C82.4417 61.6556 61.1306 47.3302 43.0019 48.9684C24.8731 50.6066 -2 61.1481 -2 61.1481Z" fill="#4257B2" stroke="white" strokeDasharray="4.4 4.4" />
                        </svg>

                    </div>
                </div>
                <div className="relative h-48 overflow-hidden shadow-card rounded-xl bg-gray-200 dark:bg-gray-400">
                    <div className='p-5 text-gray-800'>
                        <h3 className='text-xl font-semibold'>Total Sales</h3>
                        <h1 className='text-5xl font-bold'>200</h1>
                    </div>
                    <div className='absolute left-0 bottom-0 w-full h-auto'>
                        <svg viewBox="0 0 250 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-4 61.1481V85H265V11.9219C265 11.9219 265 -7.87001 247.808 5.83208C230.617 19.5342 234.156 29.1765 219.492 33.7438C204.829 38.3112 197.356 22.4196 169.434 24.1016C141.512 25.7836 125.949 54.5508 103.195 58.1032C80.4417 61.6556 59.1306 47.3302 41.0019 48.9684C22.8731 50.6066 -4 61.1481 -4 61.1481Z" fill="url(#paint0_linear)" stroke="white" strokeDasharray="4.4 4.4" />
                            <defs>
                                <linearGradient id="paint0_linear" x1="130.5" y1="1" x2="130.5" y2="85" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#5F7DFF" stopOpacity="0.73" />
                                    <stop offset="1" stopColor="#5F7DFF" stopOpacity="0.24" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className="relative h-48 overflow-hidden shadow-card rounded-xl bg-gray-200 dark:bg-gray-400">
                    <div className='p-5 text-gray-800'>
                        <h3 className='text-xl font-semibold'>Total Sales</h3>
                        <h1 className='text-5xl font-bold'>200</h1>
                    </div>
                    <div className='absolute left-0 bottom-0 w-full h-auto'>
                        <svg viewBox="0 0 250 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-4 61.1481V85H265V11.9219C265 11.9219 265 -7.87001 247.808 5.83208C230.617 19.5342 234.156 29.1765 219.492 33.7438C204.829 38.3112 197.356 22.4196 169.434 24.1016C141.512 25.7836 125.949 54.5508 103.195 58.1032C80.4417 61.6556 59.1306 47.3302 41.0019 48.9684C22.8731 50.6066 -4 61.1481 -4 61.1481Z" fill="url(#paint0_linear)" stroke="white" strokeDasharray="4.4 4.4" />
                            <defs>
                                <linearGradient id="paint0_linear" x1="130.5" y1="1" x2="130.5" y2="85" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#5F7DFF" stopOpacity="0.73" />
                                    <stop offset="1" stopColor="#5F7DFF" stopOpacity="0.24" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className="relative h-48 overflow-hidden shadow-card rounded-xl bg-gray-200 dark:bg-gray-400">
                    <div className='p-5 text-gray-800'>
                        <h3 className='text-xl font-semibold'>Total Sales</h3>
                        <h1 className='text-5xl font-bold'>200</h1>
                    </div>
                    <div className='absolute left-0 bottom-0 w-full h-auto'>
                        <svg viewBox="0 0 250 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-4 61.1481V85H265V11.9219C265 11.9219 265 -7.87001 247.808 5.83208C230.617 19.5342 234.156 29.1765 219.492 33.7438C204.829 38.3112 197.356 22.4196 169.434 24.1016C141.512 25.7836 125.949 54.5508 103.195 58.1032C80.4417 61.6556 59.1306 47.3302 41.0019 48.9684C22.8731 50.6066 -4 61.1481 -4 61.1481Z" fill="url(#paint0_linear)" stroke="white" strokeDasharray="4.4 4.4" />
                            <defs>
                                <linearGradient id="paint0_linear" x1="130.5" y1="1" x2="130.5" y2="85" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#5F7DFF" stopOpacity="0.73" />
                                    <stop offset="1" stopColor="#5F7DFF" stopOpacity="0.24" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
