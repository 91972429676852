import { useContext, useEffect } from 'react'
import { DispatchContext, StateContext } from '../context/app.context';

export type IQueryKey = string | [string, any]
export interface IConfig {
    enabled?: boolean
}
interface IReturnValue {
    data: any;
    error: any;
    loading: boolean
    mutate: () => void
}

const defaultConfig: IConfig = {
    enabled: true
}

export const defaultReturnValue: IReturnValue = {
    data: undefined,
    error: undefined,
    loading: false,
    mutate: () => { }
}

export const useRequest = (queryKey: IQueryKey, fn: (queryKey: IQueryKey) => Promise<any>, config: IConfig = defaultConfig) => {
    let key: string;
    if (typeof queryKey === 'string') {
        key = queryKey;
    } else {
        key = `[${queryKey[0]}, ${JSON.stringify(queryKey[1])}]`
    }
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const { enabled } = config;

    useEffect(() => {
        if (enabled) {
            mutate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled, key]);

    async function mutate() {
        try {
            dispatch({ type: "api_loading", payload: { key: key, data: true } })
            const data = await fn(queryKey);
            dispatch({ type: "api_response", payload: { key: key, data } });
        } catch (err) {
            const errResponse = err.response ? err.response : err.request ? err.request : { message: err.message }
            dispatch({ type: "api_error", payload: { key: key, data: errResponse } });
        } finally {
            dispatch({ type: "api_loading", payload: { key: key, data: false } })
        }
    }

    return { ...defaultReturnValue, ...state.api[key], mutate }
}

export const useMutation = (fn: (config?: any) => Promise<any>) => {
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);

    const mutate = async (config?: any) => {
        try {
            state.api.mutation.data && dispatch({ type: "api_data", payload: { key: "mutation", data: undefined } })
            state.api.mutation.error && dispatch({ type: "api_error", payload: { key: "mutation", data: undefined } })
            dispatch({ type: "api_loading", payload: { key: "mutation", data: true } })
            const data = await fn(config);
            dispatch({ type: "api_response", payload: { key: "mutation", data } });
        } catch (err) {
            const errResponse = err.response ? err.response : err.request ? err.request : { message: err.message }
            dispatch({ type: "api_error", payload: { key: "mutation", data: errResponse } });
        } finally {
            dispatch({ type: "api_loading", payload: { key: "mutation", data: false } })
        }
    }

    return { ...defaultReturnValue, ...state.api.mutation, mutate }
}
