import { ExternalLinkIcon, EyeIcon } from '@heroicons/react/solid';
import React from 'react';

interface ITable {
    data: Array<any>
}

const Table = ({ data }: ITable) => {
    return (
        <div className='overflow-auto'>
            <table className='w-full'>
                <thead>
                    <tr className='border-b border-gray-200 dark:border-gray-700'>
                        <th className='py-5 px-4 text-left'>Product Name</th>
                        <th className='py-5 px-4 text-left'>Quantity</th>
                        <th className='py-5 px-4 text-left'>Price</th>
                        <th className='py-5 px-4 text-left'>Updated At</th>
                        <th className='py-5 px-4'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((res, index) => (
                        <tr key={res.name} className={`dark:hover:bg-gray-700 hover:bg-gray-300 ${index % 2 === 0 ? '' : 'bg-gray-100 dark:bg-gray-800'}`}>
                            <td className='py-2 px-4'>{res.name}</td>
                            <td className='py-2 px-4'>{res.quantity}</td>
                            <td className='py-2 px-4 text-right'>{res.price}</td>
                            <td className='py-2 px-4'>{res.updatedAt}</td>
                            <td className='py-2 px-4 flex space-x-2 justify-center'>
                                <button className='h-10 w-10 bg-green-500 rounded-md'><EyeIcon className='w-6 h-6 mx-auto my-auto text-gray-200' /></button>
                                <button className='h-10 w-10 bg-blue-500 rounded-md'><ExternalLinkIcon className='w-6 h-6 mx-auto my-auto text-gray-200' /></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Table
