import React, { useEffect } from 'react';
import { keepTheme } from './helper/themeSwitcher';
import Header from './layouts/header';
import SideBar from './layouts/sidebar';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './route/route';
import AppContext from './context/app.context';

const App = () => {
    useEffect(() => {
        keepTheme();
    })

    return (
        <AppContext>
            <Router>
                <div className="sm:flex sm:flex-row h-screen w-full text-gray-800 dark:text-gray-200 dark:bg-gray-900 sm:dark:bg-primary sm:bg-primary">
                    <div className="fixed top-0 left-0 sm:left-28 main-width mobile-content w-full sm:h-full bg-white dark:bg-gray-900 sm:rounded-l-3xl overflow-y-auto">
                        <Header />
                        <main className="px-11">
                            <Routes />
                        </main>
                    </div>
                    <SideBar />
                </div>
            </Router>
        </AppContext>
    );
}

export default App;
