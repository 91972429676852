import { IQueryKey } from "./httpClient";
import axios from "axios";

export const getTodosById = async (queryKey: IQueryKey) => {
    const [, { id }] = queryKey;
    try {
        const { data } = await axios.get(`https://jsonplaceholder.typicode.com/todos/${id}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
}

export const getAllPosts = async () => {
    try {
        const { data } = await axios.get("https://jsonplaceholder.typicode.com/posts");
        return data;
    } catch (err) {
        throw new Error(err);
    }
}

export const addPost = async (body: any) => {
    try {
        const { data } = await axios.post("https://jsonplaceholder.typicode.com/posts", body)
        return data;
    } catch (err) {
        throw new Error(err);
    }
}