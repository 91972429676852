import React from 'react'
import { render } from 'react-dom'
import App from './App'
import "./main.css"

render(<App />, document.getElementById('root'));

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('sw.js').then(console.log).catch(console.error)
    })
}