export const menuItems = [
    {
        name: 'Home',
        link: '/home',
        // icon: <HomeIcon className="h-8 w-8" />
    },
    {
        name: 'Product',
        link: '/product',
        // icon: <HomeIcon className="h-8 w-8" />
    },
    {
        name: 'Amount',
        link: '/amount',
        // icon: <HomeIcon className="h-8 w-8" />
    },
    {
        name: 'Profile',
        link: '/profile',
        // icon: <HomeIcon className="h-8 w-8" />
    },
    {
        name: 'Gifts',
        link: '/gifts',
        // icon: <HomeIcon className="h-8 w-8" />
    }
]