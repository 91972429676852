import React from 'react'

const Product = () => {
    return (
        <div>
            <p>No Such Page Exists</p>
        </div>
    )
}

export default Product
