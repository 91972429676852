import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, PlusIcon } from '@heroicons/react/solid';
import React from 'react'
import Table from '../../elements/Table';

const arr = [
    {
        name: "Clay Pot",
        quantity: 4,
        price: 100,
        updatedAt: '2020/01/21'
    },
    {
        name: "Sinament Candle",
        quantity: 11,
        price: 1210,
        updatedAt: '2020/05/21'
    },
    {
        name: "Vase",
        quantity: 1,
        price: 99,
        updatedAt: '2020/09/21'
    }
]

const Product = () => {
    return (
        <div className='mt-8'>
            <h2 className='text-3xl font-semibold'>Your Products</h2>
            <div className='my-8 shadow-sm border-2 border-gray-200 dark:border-gray-700 rounded-lg'>
                <div className='px-5 py-4 flex justify-between border-b border-gray-200 dark:border-gray-700'>
                    <h3 className='text-xl font-semibold'>Product Lists</h3>
                    <button className='bg-primary py-2 px-3 rounded-md focus:outline-none text-gray-200 flex'>
                        <PlusIcon className='w-6 h-6 my-auto sm:mr-1' />
                        <p className='hidden sm:block'>Add new product</p>
                    </button>
                </div>
                <div className='px-5 py-4 flex flex-col sm:flex-row space-y-3 sm:space-y-0 justify-between border-b border-gray-200 dark:border-gray-700'>
                    <button className='py-2 px-3 rounded-md focus:outline-none border border-gray-200 dark:border-gray-700'>Filter</button>
                    <input type='text' placeholder='search' className='rounded-md border border-gray-200 dark:border-gray-700 dark:bg-gray-300 text-gray-800' />
                </div>
                <Table data={arr} />
                <div className='flex justify-center my-4'>
                    <ul className='flex'>
                        <li className='border-2 border-gray-200 dark:border-gray-700 py-2 px-4'><ChevronDoubleLeftIcon className='w-6 h-6 m-auto' /></li>
                        {Array(5).fill(0).map((res, index) => (
                            <li key={index} className='hidden sm:block border-2 border-gray-200 dark:border-gray-700 py-2 px-4'>{index + 1}</li>
                        ))}
                        <li className='sm:hidden border-2 border-gray-200 dark:border-gray-700 py-2 px-4'>1</li>
                        <li className='border-2 border-gray-200 dark:border-gray-700 py-2 px-4'><ChevronDoubleRightIcon className='w-6 h-6 m-auto' /></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Product
